/* Main Button Area css
============================================================================================ */
.submit_btn{
	width: auto;
	display: inline-block;
	background: $white_color;
	padding: 0px 50px;
	color: #fff;
	font-size: 13px;
	font-weight: 500;
	line-height: 50px;
	border-radius: 5px;
	outline: none !important;
	box-shadow: none !important;
	text-align: center;
	border: 1px solid $border_color;
	cursor: pointer;
	@include transform_time(0.5s);
	&:hover{
		background: transparent;
		
	}
}
.btn_1{
	display: inline-block;
	padding: 17px 50px;
	border-radius: 5px;
    background-color: $primary-color;
    border: 2px solid $primary-color;
    font-size: 15px;
    font-weight: 500;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    text-transform: capitalize;
    color: #fff;
	&:hover{
		background-color: $white_color;
		color: $btn_bg;
	}
	@media #{$tab}{
		padding: 13px 35px;
	}
}
.btn_3{
	display: inline-block;
	padding: 18px 36px;
	border-radius: 5px;
	background-color: transparent;
	border: 1px solid $primary-color;
	font-size: 15px;
	font-weight: 700;
	color: $primary-color;
	text-transform: uppercase;
	font-weight: 400;
	@include transform_time(0.5s);
	&:hover{
		background-color: $primary-color;
		color: $white_color;
	}
	@media #{$tab}{
		padding: 13px 35px;
	}
}
.btn_2{
	display: inline-block;
	width: 180px;
	height: 60px;
	line-height: 60px;
	text-align: center;
	background-color: $white;
	font-size: 15px;
	font-family: $font_stack_2;
	font-weight: 500;
	color: #1e1e1e;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
	text-transform: capitalize;
	&:hover{
		color: $white_color !important;
		background-color: $primary-color;
	}
	@media #{$tab}{
		height: 50px;
		width: 140px;
		line-height: 50px;
	}
	@media #{$medium_device}{
	
	}
}


.btn_4{
	color: $btn_bg;
	font-size: 15px;
	font-weight: 700;
	margin-top: 53px;
	display: inline-block;
	@include transform_time(0.5s);
	border-radius: 5px;

	@media #{$tab}{
		margin-top: 30px;
	}
	@media #{$medium_device}{
	
	}
	img{
		width: 20px;
		margin-left: 15px;
	}

}
/*=================== custom button rule start ====================*/

.button{
	display: inline-block;
	border: 1px solid transparent;
	font-size: 15px;
	font-weight: 500;
	padding: 12px 54px;
	border-radius: 4px;
	color: $white_color;
	border: 1px solid $border_color;
	text-transform: uppercase;
	background-color: $btn_bg;
	cursor: pointer;
	@include transform_time(0.5s);

	@media(max-width: 767px){
		font-size: 13px;
		padding: 9px 24px;
	}

	&:hover{
		color: $white_color;
	}


	&-link{
		letter-spacing: 0;
		color: #3b1d82;
		border: 0;
		padding: 0;

		&:hover{
			background: transparent;
			color: #3b1d82;
		}
	}

	&-header{
		color: $white_color;
		border-color: $border_color;

		&:hover{
			background: #b8024c;
			color: $white_color;
		}
	}

	&-contactForm{
		color: $white_color;
		border-color: $border_color;
		padding: 12px 25px;

		&:hover{
			// border-color: $title-color;
			// background: $title-color;
			// color: $white_color;
		}
	}
}


/* End Main Button Area css
============================================================================================ */
/**************** banner part css start ****************/
.banner_part{
    position: relative;
    height: 782px;
    display: flex;
    align-items: center;
    @media #{$tab}{
        height: 550px;
     }
     @media #{$tab_device}{
         height: 420px;
     }
     @media #{$medium_device}{
         height: 420px;
     }
     @media #{$big_screen}{
         height: 600px;
     }
    &:after{
        position: absolute;
        content: "";
        left: 0;
        bottom: 0;
        background-color: $primary-color;
        width: 196px;
        height: 618px;
        @media #{$tab}{
            display: none;
        }
        @media #{$medium_device}{
            display: none;
        }
        @media #{$big_screen}{
            display: none;
        }
    }
    .banner_text{
        @media #{$tab}{
            margin-top: -150px;
        }
        @media #{$tab_device}{
            margin-top: 0;
        }
        
        h1{
            font-size: 68px;
            font-weight: 500;
            line-height: 78px;
            margin-bottom: 10px;
            @media #{$small_mobile}{
                font-size: 30px;
                margin-bottom: 15px;
                line-height: 1.3;
            }
            @media #{$large_mobile}{
                font-size: 30px;
                margin-bottom: 15px;
                line-height: 1.3;
            }
            @media #{$tab_device}{
                font-size: 40px;
                margin-bottom: 15px;
                line-height: 1.3;
            }
            @media #{$medium_device}{
                font-size: 40px;
                margin-bottom: 15px;
                line-height: 1.4;
            }
            span{
                color: $btn_bg;
            }
        }
        p{
            font-size: 20px;
            line-height: 1.5;
        }
    }
    .banner_img{
        position: absolute;
        right: 0;
        top: 0;
        max-width: 55%;
        @media #{$big_screen}{
            max-width: 55%; 
            top: auto;
            bottom: 0;
        }
        @media #{$medium_device}{
            max-width: 55%; 
            top: auto;
            bottom: 0;
        }
        @media #{$small_mobile}{
            max-width: 75%; 
            top: auto;
            bottom: 0;
        }
        @media #{$large_mobile}{
            max-width: 55%; 
            top: auto;
            bottom: 0;
        }
        @media #{$tab_device}{
            max-width: 55%; 
            top: auto;
            bottom: 0;
        }
        .pattern_img{
            position: absolute;
            left: -133px;
            bottom: 0;
            @media #{$medium_device}{
                max-width: 75%; 
            }
            @media #{$small_mobile}{
                max-width: 60%; 
            }
            @media #{$large_mobile}{
                max-width: 55%; 
            }
            @media #{$tab_device}{
                max-width: 55%; 
            }
        }
    }
    .btn_1{
        margin-top: 50px;
        @media #{$tab}{
            margin-top: 20px;   
            padding: 12px 25px;    
        }
        @media #{$medium_device}{
            margin-top: 20px;       
        }
    }
} 
/**************** hero part css end ****************/

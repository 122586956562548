/******************* client_logo *****************/
.client_logo{
    .single_client_logo{
        height: 125px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border-left: 1px solid $border_color;
        width: 20%;
        float: left;
        border-bottom: 1px solid $border_color;

        @media #{$small_mobile}{
            height: 70px;
            width: 25%;
            padding: 6px;
            border-bottom: 0px solid transparent;
            border-left: 0px solid transparent;
        }
        @media #{$large_mobile}{
        height: 100px;
        padding: 8px;
        }
        @media #{$tab_device}{
            height: 100px;
            padding: 10px;
        }
        @media #{$medium_device}{
        
        }

        &:nth-child(5n+1){
            border-left: 0px solid transparent;
        }
        &:nth-child(n+6){
            border-bottom: 0px solid transparent;
        }
        @media #{$small_mobile}{

        }
        @media #{$large_mobile}{
        
        }
        @media #{$tab_device}{
        
        }
        @media #{$medium_device}{
        
        }
        img{
            filter: grayscale(1);
            @include transform_time(0.5s);
            &:hover{
                filter: grayscale(0);
            }
        }
    }
}
/************ new arrival css here ****************/
.arrival_tittle, .arrival_filter_item{
    margin-bottom: 80px;
    @media #{$tab}{
       margin-bottom: 30px;
    }
    h2{
       font-size: 50px;
       font-weight: 700; 
       text-transform: capitalize;
       @media #{$tab}{
           font-size: 30px;
       }
    }
    ul li{
        font-size: 16px;
        color: $black_color;
        text-transform: capitalize;
        font-family: $font_stack_2; 
        margin-left: 35px;
        display: inline-block;
        cursor: pointer;
        @media #{$tab}{
            margin-left: 0;
            margin-right: 30px;
        }
    }
}
.new_arrival{
    .col-lg-12{
        padding-right: 0 !important;
        padding-left: 0 !important;
        overflow: hidden;
        @media #{$tab}{
            padding-right: 15px !important;
            padding-left: 15px !important;
        }
    }
    .new_arrival_iner{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .weidth_1{
            flex: 39% 0 0;    
            max-width: 39%;
            @media #{$small_mobile}{
                flex: 100% 0 0;    
                max-width: 100%;
            }
        }
        .weidth_2{
            flex: 28% 0 0;    
            max-width: 28%;
            @media #{$small_mobile}{
                flex: 100% 0 0;    
                max-width: 100%;
            }
        }
        .weidth_3{
            flex: 29.25% 0 0;    
            max-width: 29.25%;
            @media #{$small_mobile}{
                flex: 100% 0 0;    
                max-width: 100%;
            }
        }
    }
    .single_arrivel_item{
        margin-bottom: 30px;
        text-align: center;
        position: relative;
        z-index: 1;
        overflow: hidden;
        @media #{$tab}{
            margin-bottom: 15px;
        }
        &:after{
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: $primary-color;
            opacity: 0;
            @include transform_time(.5s);
            visibility: hidden;
        }
        img{
            width: 100%;
            max-height: 550px;
            @include transform_time(.5s);
        }
        .hover_text{
            position: absolute;
            top: 28%;
            z-index: 2;
            left: 0;
            right: 0;
            margin: 0 auto;
            color: $white;
            @include transform_time(.5s);
            opacity: 0;
            visibility: hidden;
            p{
                font-size: 16px;
                color: $white;
                letter-spacing: 2;
            }
            h3{
                font-size: 30px;
                color: $white;
                margin: 2px 0 14px;
                @media #{$tab}{
                    font-size: 20px;
                    margin: 2px 0 8px;
                }
            }
            .rate_icon{
                i{
                    color: #ffe400 !important;
                }
               
            }
            h5{
                font-size: 24px;
                color: $white;
                margin: 18px 0 26px;
                @media #{$tab}{
                    font-size: 20px;
                    margin: 2px 0 8px;
                }
            }
            .social_icon{
                a{
                    display: inline-block;
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    color: $black_color;
                    background-color: $white;
                    border-radius: 50%;
                    margin: 0 7px;
                    &:hover{
                        background-color: $primary-color;
                        color: $white_color;
                    }
                }
            }
        }
        &:hover{
            &:after{
                opacity: .7;
                visibility: visible;
            }
            .hover_text{
                top: 31%;
                opacity: 1;
                visibility: visible;
                @media #{$tab}{
                    top: 20%;
                }
            }
            img{
                transform: scale(1.1);
            }
        }
    }
    .active{
        position: relative;
        z-index: 1;
        color: $primary-color;
        &:after{
            position: absolute;
            content: "";
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            background-color: $primary-color;

        }
    }
}
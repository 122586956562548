/**************** extend css start ****************/
%custom_btn_bg_1{
    background-image: linear-gradient( 16deg, rgb(255,0,90) 0%, rgb(255,93,45) 64%, rgb(255,186,0) 100%);  
}


%custom_btn_bg_2{
    background-image: linear-gradient(to left, #57ddff 0%, #57ddff 51%, #57ddff 100%);  
}
%custom_btn_bg_3{
    background-image: linear-gradient(to left, #fff 0%, #fff 51%, #fff 100%);  
}
%rank_bg{
    background: -moz-linear-gradient( 16deg, rgb(250,113,205) 0%, rgb(181,77,243) 100%);
    background: -webkit-linear-gradient( 16deg, rgb(250,113,205) 0%, rgb(181,77,243) 100%);
    background: -ms-linear-gradient( 16deg, rgb(250,113,205) 0%, rgb(181,77,243) 100%);
    box-shadow: 0px 10px 20px 0px rgba(196, 113, 245, 0.3);
}
  
%overlay_bg{
    background: -moz-linear-gradient( 16deg, rgb(250,113,205) 0%, rgb(181,77,243) 100%);
    background: -webkit-linear-gradient( 16deg, rgb(250,113,205) 0%, rgb(181,77,243) 100%);
    background: -ms-linear-gradient( 16deg, rgb(250,113,205) 0%, rgb(181,77,243) 100%);
}
%pricing_btn_bg_bg {
    background: -moz-linear-gradient( 90deg, rgb(173,35,254) 0%, rgb(250,96,199) 100%);
    background: -webkit-linear-gradient( 90deg, rgb(173,35,254) 0%, rgb(250,96,199) 100%);
    background: -ms-linear-gradient( 90deg, rgb(173,35,254) 0%, rgb(250,96,199) 100%);
    box-shadow: 0px 8px 15px 0px rgba(180, 41, 248, 0.25);
}
  
%icon_bg{
    background: -moz-linear-gradient( 45deg, rgb(173,35,254) 0%, rgb(250,96,199) 100%);
    background: -webkit-linear-gradient( 45deg, rgb(173,35,254) 0%, rgb(250,96,199) 100%);
    background: -ms-linear-gradient( 45deg, rgb(173,35,254) 0%, rgb(250,96,199) 100%);
}

/**************** extend css start ****************/

/************** confirmation part css **********************/
.confirmation_part{
    .confirmation_tittle{
        text-align: center;
        margin-bottom: 35px;
        span{
            color: $btn_bg;
            
        }
    }
    .single_confirmation_details{
        background-color: $section_bg;
        padding: 35px 40px;
        @media #{$tab}{
            margin-bottom: 15px;
        }
        @media #{$medium_device}{
            margin-bottom: 30px;
        }
        h4{
            font-size: 18px;
            font-weight: 500;
            color: $font_2;
            text-transform: capitalize;
            margin-bottom: 16px;
        }
        ul{
            li{
                position: relative;
                text-transform: capitalize;
                
                padding-left: 55%;
                margin-bottom: 3px;
                p{
                    position: absolute;
                    left: 0;
                    top: 0;
                    line-height: 21px;
                    color: $font_7;
                }
                span{
                    color: $font_2;
                }
            }
        }
    }
    .order_details_iner{
        margin-top: 50px;
        background-color: $section_bg;
        padding: 30px 30px 15px;
        text-transform: capitalize;
        h3{
            font-size: 18px;
        }
        .table thead th{
           color: $font_2;
           font-size: 16px;
        }
        span{
            color: $font_7;
        }
        thead{
            border-top: 1px solid $font_d;
            border-bottom: 1px solid $font_d;
        }
        tfoot{
            border-top: 1px solid $font_d;
        }
        th{
            font-weight: 500;
        }
    }
}
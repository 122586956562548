/***************** product list css *******************/
.best_seller{
    .single_product_item{
        border-radius: 5px;
        margin-bottom: 20px;
        @include transform_time(0.5s);
        .single_product_text{
            padding: 35px 0 0;
            background-color: $white_color;
            @include transform_time(0.5s);
            h4{
                font-weight: 700;
                font-size: 18px;
                margin-bottom: 14px;
            }
            h3{
                font-weight: 300;
                font-size: 18px;
            }
            a{
                color: $primary_color;
                text-transform: uppercase;
                font-size: 18px;
                font-weight: 500;
                display: block;
                margin-top: 10px;
                opacity: 0;
                visibility: hidden;
                @include transform_time(0.5s);
                i{
                    float: right;
                    font-size: 18px;
                    line-height: 26px;
                    color: $black_color;
                }
            }
        }
        &:hover{
            box-shadow: none;
            .single_product_text{
                padding: 32px 0 0;
            }
            
            a{
                opacity: 1;
                visibility: visible;
            }   
        }
    }
    
}
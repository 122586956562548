@mixin background($imgpath,$position: center,$size: cover,$repeat: no-repeat) {
    background: {
        image: url($imgpath);
        position: $position;
        repeat: $repeat;
        size: $size;
    }
}
@mixin transform_time($total_time) {
    -webkit-transition: $total_time;
    transition: $total_time;
}
@mixin placeholder {
	&.placeholder {
		@content;
	}
	&:-moz-placeholder {
		@content;
	}
	&::-moz-placeholder {
		@content;
	}
	&::-webkit-input-placeholder {
		@content;
	}
}
@mixin transition($args: all 0.6s ease 0s) {
	-webkit-transition: $args;
	-moz-transition: $args;
	-o-transition: $args;
	transition: $args;
}

@mixin keyframes ($animation-name) {
	@-webkit-keyframes #{$animation-name} {
		@content;
	}
	@-moz-keyframes #{$animation-name} {
		@content;
	}
	@-o-keyframes #{$animation-name} {
		@content;
	}
	@keyframes #{$animation-name} {
		@content;
	}
}
@mixin transition($property: all,
$duration: 300ms,
$animate: linear,
$delay:0s) {
    transition: $property $duration $animate $delay;
}

@mixin transform($transform) {
    -webkit-transform: $transform;
    -moz-transform: $transform;
    -o-transform: $transform;
    -ms-transform: $transform;
    transform: $transform;
}

// Placeholder Mixins
@mixin placeholder {
    &.placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &::-webkit-input-placeholder {
        @content;
    }
}